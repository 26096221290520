(function(){

	var alvoToggle = $('#trocador');

	var gatilhoToggle = $('.ativador');

	gatilhoToggle.click(function(){
		alvoToggle.toggleClass('ativado');
	});

})();