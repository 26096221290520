var banner = (function(){
	var length = $('#banner .carousel-item').length;

	$('#banner .carousel-item').each(function(index,el){
		var thisContent = $(this).children(':first-child');
		var nextContent = $(this).prev().children(':first-child').clone();

		if(index == 0){
			nextContent = $('#banner .carousel-item:last-child').children(':first-child').clone();
		}

		nextContent.addClass('clone')

		$(this).append(nextContent);

	});


	$('#banner').carousel({
		interval: 5000
	})

})();