(function(){

	var alvoVigia = $('.mbl-controls');

	var vigiarHeight = $('.topo').innerHeight();

	$(window).scroll(function(){

		if($(this).scrollTop() > vigiarHeight){
			alvoVigia.addClass('fixo')
		}else{
			alvoVigia.removeClass('fixo')
		}

	});

})();