(function(){

	var dropdownCustom = $('.dropdown-custom');

	dropdownCustom.on('show.bs.dropdown', function(){
		$(this).addClass('active');
	});

	dropdownCustom.on('hide.bs.dropdown', function(){
		$(this).removeClass('active');
	});

})();