(function(){

	var dropdownCustom = $('.dropdown-custom');

	dropdownCustom.each(function(){

		$(this).hover(function(){

			$(this).find('.dropdown-menu').toggleClass('show');

		});

	});

})();